
import { Actions } from '../actions'
import {ReservationState} from '../common/ReservationState'

const initialState = {
    username: '',
    userProfile: [],
    timeSlots: [],
    noOfGuests: [],
    errorObject: [],
    isBookingError: false,
    isBookSucceeded: false,
    availableSeats: '',
    isfetching: false,
    isAddingReservation: false,
    reservationState: ReservationState.NOTRESERVE,
    holidays: [],
    closingDates: [],

}

export default function Reservation(state = initialState, action) {
    switch (action.type) {
        case Actions.Reservation.REQUEST_GET_USER:
            return Object.assign({}, state, {
                userProfile: action.payload,
            })
        case Actions.Reservation.REQUEST_GET_TIMESLOTS:
            return Object.assign({}, state, {
                isfetching: true,
            })
        case Actions.Reservation.REQUEST_GET_TIMESLOTS_SUCCEEDED:
            return Object.assign({}, state, {
                timeSlots: action.payload,
                // isfetching: false,
                isBookingError: false,
            })
        case Actions.Reservation.REQUEST_GET_TIMESLOTS_FAILED:
            return Object.assign({}, state, {
                errorObject: action.payload,
                timeSlots: [],
                isBookingError: false,
            })
        case Actions.Reservation.REQUEST_GET_AVAILABLE_SEATS:
            return Object.assign({}, state, {
                isfetching: true,
            })
        case Actions.Reservation.REQUEST_GET_NOOFGUESTS_SUCCEEDED:
            return Object.assign({}, state, {
                noOfGuests: action.payload,
                // isfetching: false,
            })
        case Actions.Reservation.REQUEST_GET_NOOFGUESTS_FAILED:
            return Object.assign({}, state, {
                errorObject: action.payload,
                isBookingError: true,
            })
        case Actions.Reservation.REQUEST_GET_NOOFGUESTS:
            return Object.assign({}, state, {
                isfetching: true,
            })
        case Actions.Reservation.REQUEST_CONFIRM_BOOKING:
            return Object.assign({}, state, {
                reservationState: ReservationState.WAITINGCONFIRM
            })
        case Actions.Reservation.REQUEST_ADD_RESERVATION:
            return Object.assign({}, state, {
                isAddingReservation: true,
                reservationState: ReservationState.RESERVING
            })
        case Actions.Reservation.REQUEST_ADD_RESERVATION_FAILED:
            return Object.assign({}, state, {
                errorObject: action.payload,
                isBookingError: true,
                isAddingReservation: false,
                reservationState: ReservationState.RESERVEFAIL

            })
        case Actions.Reservation.REQUEST_ADD_RESERVATION_SUCCEEDED:
            return Object.assign({}, state, {
                isBookSucceeded: true,
                isAddingReservation: false,
                reservationState: ReservationState.RESERVESUCCESS
            })
        case Actions.Reservation.REQUEST_RESELECT_TIMESLOT:
            return Object.assign({}, state, {
                errorObject: [],
                isBookingError: false,
                reservationState: ReservationState.NOTRESERVE
            })
        case Actions.Reservation.FINISH_BOOKING:
            return Object.assign({}, state, {
                isBookSucceeded: false,
                reservationState: ReservationState.NOTRESERVE
            })
        case Actions.Reservation.REQUEST_GET_AVAILABLE_SEATS_SUCCEEDED:
            return Object.assign({}, state, {
                availableSeats: action.payload.availability,
                capacity: action.payload.capacity,
                isfetching: false,
            })
        case Actions.Reservation.REQUEST_GET_AVAILABLE_SEATS_FAILED:
            return Object.assign({}, state, {
                availableSeats: action.payload.availability,
                errorObject: action.payload,
                isfetching: false,
            })
        case Actions.Reservation.REQUEST_GET_HOLIDAY_SUCCESS:
            return Object.assign({}, state, {
                holidays: action.payload,
            })
        case Actions.Reservation.REQUEST_GET_CLOSINGDATE_SUCCESS:
            return Object.assign({}, state, {
                closingDates: action.payload,
            })
        default:
            return state
    }
}