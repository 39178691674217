import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Actions } from '../../actions';
import { useSelector, useDispatch } from 'react-redux'
import Reminders from '../Views/Reminders'

export default function ReservationDetail(props) {

  const [QRcodeSrc, setQRCodeSrc] = useState([]);
  const [capacity] = useState([7]);

  const QRCodecounter = useSelector((state) => state.ListReservation.QRCode);

  const dispatch = useDispatch()

  useEffect(() => {
    console.log("reservationID: ", props.reservationData.reservationID);
    dispatch(Actions.ListReservation.fn_RequestGetReservationDetail(props.reservationData.reservationID));
  }, [props, dispatch])

  useEffect(() => {
    const generateQR = async () => {
      try {
        let image = new Image();
        image.src = 'data:image/png;base64,' + QRCodecounter;
        setQRCodeSrc(image.src)

      } catch (err) {
        console.error(err)
      }
    };
    generateQR();
  }, [QRCodecounter])

  return (

    <div>
      <Dialog
        open={true}
        onClose={props.handleReservationDetailClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{textAlign: 'center', fontFamily: '"Lato Light", sans-serif', fontSize: 20, color: 'white', backgroundColor: 'rgb(226, 33, 28)'}}>{"Booking Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"  component={'span'} variant={"body2"}>
            <div>
              <img src={QRcodeSrc} alt='QRCode' style={{display: 'flex', justifyContent: 'center', margin: 'auto'}} />
              <div style={{ justifyContent: 'center'}}>
                <span style={{ display: 'flex', justifyContent: 'center', margin: 'auto', color: 'black', fontSize: '110%' }}>
                  Date: {props.reservationData.date} </span>
                <span style={{ display: 'flex', justifyContent: 'center', margin: 'auto', color: 'black', fontSize: '110%' }}>
                  Time: {props.reservationData.startTimeDisplay}</span>
                <span style={{ display: 'flex', justifyContent: 'center', margin: 'auto', color: 'black', fontSize: '110%' }}>
                  Number of guests: {props.reservationData.guests}  </span>
              </div>
              <Reminders capacity={capacity} />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleReservationDetailClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

// export default (ReservationDetail)