import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SendIcon from '@material-ui/icons/Send';
import { useGraph } from "@microsoft/teamsfx-react";
import moment, { now } from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Actions } from '../../actions';
import '../../App.css';
import { TeamsFxContext } from "../../Context";
import DialogBox from '../BookNow/DialogBox';
import Reminders from '../Views/Reminders';
import './Calendar.css';
import { ReservationState } from '../../common/ReservationState'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: '40',
    display: 'flex',
    marginLeft: '10%',
    width: '80%',
  },
  colorPrimary: {
    backgroundColor: 'rgb(171, 182, 203)',
  },
  bar: (props) => ({
    background: props.progresscolor,
  })
}))(LinearProgress);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    fontSize: 19,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      "'Lato Light', sans-serif"
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%"
  },
  btn: {
    "&:hover": {
      backgroundColor: '#dc2f26'
    },
    background: '#da1e15',
    color: 'White',
    marginTop: 15,
    width: '80%',
  },
  formControl: {
    marginLeft: 'auto',
    marginBottom: 10,
    minWidth: '20%',
    textAlign: 'center',
    paddingTop: 20
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  calendarContainer: {
    fontFamily: '"Lato Light", sans-serif',
    marginBottom: '5%',
    textAlign: 'center',
    width: '100%'
  }
}));


export default function BookingCalendar(props) {
  const classes = useStyles();
  const history = useHistory();
  const [selectedTime, setSelectedTime] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([])
  const [noOfGuests, setNoOfGuests] = useState([])
  const [numOfGuest, setNumOfGuest] = useState(0)
  // dialog
  const [open, setOpen] = useState(false);
  const [progressColor, setProgressColor] = React.useState('green')
  const [title, setTitle] = useState();
  const [context, setContext] = useState();
  const [boxAction, setBoxAction] = useState();

  const availableTimeSlots = useSelector((state) => state.Reservation.timeSlots)
  const availableNoOfGuests = useSelector((state) => state.Reservation.noOfGuests)

  const errorObject = useSelector((state) => state.Reservation.errorObject)
  const availableSeats = useSelector((state) => state.Reservation.availableSeats)

  const capacity = useSelector((state) => state.Reservation.capacity)
  const isfetching = useSelector((state) => state.Reservation.isfetching)
  const reservationState = useSelector((state) => state.Reservation.reservationState)

  const holidays = useSelector((state) => state.Reservation.holidays)
  const closingDates = useSelector((state) => state.Reservation.closingDates)

  const dispatch = useDispatch()

  const handleClickOpen = () => {
    console.log("Clicked Book")
    setOpen(true);
    dispatch(Actions.Reservation.fn_RequestConfirmBooking());
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sessionForDisplay = (selectedSession) => {
    let selectedSessionDate = moment(selectedSession).format("MMM D (ddd)");
    let startTimeString = moment(selectedSession).format("h:mm a");
    let endTime = moment(selectedSession).add(1, 'hour');
    let endTimeString = endTime.format("h:mm a");
    return (`${selectedSessionDate} ${startTimeString} - ${endTimeString}`)
  }

  //control dialog
  useEffect(() => {
    if (reservationState == ReservationState.WAITINGCONFIRM) {
      setTitle('Confirm Reservation?')
      setContext(<span style={{ color: "black" }}>Confirm reservation at <span style={{ color: "black", fontWeight: 'bold' }}>{sessionForDisplay(selectedTime)}</span>{numOfGuest > 0 ? (` with ${numOfGuest} ${numOfGuest == 1 ? 'guest' : 'guests'}`) : ''}?</span>);
      setBoxAction(<><DialogActions>
        <Button style={{ backgroundColor: '#4caf50' }} variant="contained" onClick={makeReservation} color="primary" endIcon={<SendIcon />}>
          Confirm
        </Button>
        <Button style={{ backgroundColor: 'rgb(226, 33, 28)' }} variant="contained" onClick={() => { handleClose(); dispatch(Actions.Reservation.fn_FinishBooking()); }} color="primary" endIcon={<ClearIcon />}>
          Back
        </Button>
      </DialogActions></>)
    } else if (reservationState == ReservationState.RESERVING) {
      setTitle(<>Reservation Confirming</>)
      setContext(<><div style={{ height: '15%', minHeight: '200', display: 'flex', justifyContent: 'center', margin: 'auto' }}><CircularProgress thickness={10} size={100} style={{ margin: 'auto' }} /></div></>)
      setBoxAction(<></>)
    } else if (reservationState == ReservationState.RESERVESUCCESS) {
      setTitle(<>Reservation Confirmed!</>)
      setContext(<> <span style={{ color: "black" }}>Successfully reserved <span style={{ color: "black", fontWeight: 'bold' }}>{sessionForDisplay(selectedTime)}</span>!</span></>)
      setBoxAction(<><DialogActions>
        <Button style={{ backgroundColor: '#4caf50' }} variant="contained" onClick={() => { handleClose(); dispatch(Actions.Reservation.fn_FinishBooking()); dispatch(Actions.Reservation.fn_FinishBooking(convertTimeForApi())); }} color="primary" autoFocus endIcon={<DoneAllIcon />}>
          Done
        </Button>
      </DialogActions></>)
    } else if (reservationState == ReservationState.RESERVEFAIL) {
      setTitle(<>Reservation Failed</>)
      setContext(<> <span style={{ color: "black" }}>{errorObject}</span> </>)
      setBoxAction(<><DialogActions>
        <Button style={{ backgroundColor: '#da1e15' }} variant="contained" onClick={() => { handleClose(); dispatch(Actions.Reservation.fn_RequestBookAnotherTimeSlot()); }} color="primary" autoFocus endIcon={<ClearIcon />}>
          Choose Another Session
        </Button>
      </DialogActions></>)
    }
  }, [reservationState])

  //handle expired tokens
  const teamsfx = useContext(TeamsFxContext);
  useGraph(
    async (graph, teamsfx, scope) => {
      let expTimeStored = sessionStorage.getItem('accessTokenForGraphExpTime');

      if (expTimeStored !== null && expTimeStored < now()) {
        console.log("accessTokenForGraph expired");
        const accessTokenForGraphC = await teamsfx.getCredential().getToken('https://graph.microsoft.com/User.Read');
        sessionStorage.setItem("accessTokenForGraph", accessTokenForGraphC.token);
        sessionStorage.setItem("accessTokenForGraphExpTime", accessTokenForGraphC.expiresOnTimestamp);
      }
    }, [teamsfx]);

  //initial
  useEffect(() => {
    let today = moment().format("YYYY-MM-DD");
    console.log("request timeSlots and NoOfGuests with date, ", today)
    dispatch(Actions.Reservation.fn_GetHoliday());
    dispatch(Actions.Reservation.fn_GetClosingDate());
    dispatch(Actions.Reservation.fn_RequestGetTimeSlots(today));
    dispatch(Actions.Reservation.fn_RequestGetNoOfGuests(today));
  }, [dispatch]);

  useEffect(() => {
    console.log("setNoOfGuests: ", availableNoOfGuests);
    setNoOfGuests(availableNoOfGuests);
  }, [availableNoOfGuests]);

  useEffect(() => {
    setProgressColor(() => {
      if ((availableSeats / capacity) * 100 > 80) {
        return '#4caf50'
      } else if ((availableSeats / capacity) * 100 > 30) {
        return '#ffc107'
      } else {
        return '#da1e15'
      }
    })
  }, [availableSeats])

  useEffect(() => {
    //handle today available time 
    let today = moment().format("YYYY-MM-DD")

    let selectedDateForCal = moment(selectedDate).format("YYYY-MM-DD")
    const upcomingAvailableTimeSlots = [];
    if (availableTimeSlots !== '') {
        const currentTime = new Date();
        availableTimeSlots.forEach(item => {
          let timeSlotText = item.text;
          let timeSlotTime = new Date(today);
          timeSlotTime.setHours(item.minutes / 60);
          timeSlotTime.setMinutes(item.minutes % 60);
          if (today === selectedDateForCal) {
            let timeSlotTime = new Date(today);
            timeSlotTime.setHours(item.minutes / 60);
            timeSlotTime.setMinutes(item.minutes % 60);
            if (timeSlotTime.getHours() >= currentTime.getHours()) {
              upcomingAvailableTimeSlots.push({timeSlotTime, timeSlotText});
            } 
          } else {
            let timeSlotTime = new Date(selectedDateForCal);
            timeSlotTime.setHours(item.minutes / 60);
            timeSlotTime.setMinutes(item.minutes % 60);
            upcomingAvailableTimeSlots.push({timeSlotTime, timeSlotText});
          }
        });
      } 
      console.log("setTimeSlots :", upcomingAvailableTimeSlots);
      setTimeSlots(upcomingAvailableTimeSlots);
    
  }, [availableTimeSlots]);

  useEffect(() => {
    if (timeSlots.length > 0) {
      setSelectedTime(new Date(timeSlots[0].timeSlotTime));
      console.log("Set Selected Time to ", timeSlots[0].timeSlotTime);
    }
  }, [timeSlots])

  useEffect(() => {
    if (typeof noOfGuests[0] === 'undefined') {
      console.log("Set noOfGuests to 0");
      setNumOfGuest(0);
    }
    else {
      console.log("Set noOfGuests to ", noOfGuests[0]);
      setNumOfGuest(noOfGuests[0]);
    }
  }, [noOfGuests])

  const convertTimeForApi = useCallback(() => {
    let startTimeForAPI = moment(selectedTime).format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment(selectedTime).add(1, 'hour');
    let endTimeForAPI = endTime.format("YYYY-MM-DD HH:mm:ss");
    console.log("minutesToObjectConverter converts ", startTimeForAPI, ", ", endTimeForAPI);
    return ({ startTimeForAPI, endTimeForAPI })
  }, [selectedTime, selectedDate])

  useEffect(() => {
    if (typeof selectedTime !== "undefined" && selectedTime != null && !open) {
      let setSelected = convertTimeForApi();
      dispatch(Actions.Reservation.fn_RequestGetAvailableSeat(setSelected));
    }
  }, [selectedTime, open])

  const convertTimeForReservationApi = () => {
    let startTimeForAPI = moment(selectedTime).format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment(selectedTime).add(1, 'hour');
    let endTimeForAPI = endTime.format("YYYY-MM-DD HH:mm:ss");
    console.log("convertMinutesToReservationAPIObject");
    return ({ startTimeForAPI, endTimeForAPI, numOfGuest })

  }

  const makeReservation = () => {
    console.log("selectedTime:", selectedTime);
    const userReservedObject = convertTimeForReservationApi();
    console.log("userReservedObject:", userReservedObject);
    dispatch(Actions.Reservation.fn_RequestAddReservation(userReservedObject));
  }

  const onDateChange = date => {
    console.log("onDateChange");
    setSelectedDate(date);
    let selectedDate = moment(date).format("YYYY-MM-DD");
    dispatch(Actions.Reservation.fn_RequestGetTimeSlots(selectedDate));
    dispatch(Actions.Reservation.fn_RequestGetNoOfGuests(selectedDate));
  }

  let futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 30);

  const handleTimeSlotChange = (event) => {
    console.log("handleTimeSlotChange: ", event.target.value);
    setSelectedTime(new Date(event.target.value));
    setNumOfGuest(0);
  };

  const handleGuestChange = (event) => {
    setNumOfGuest(event.target.value);
  };


  const getTileClassName = ({ date }) => {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))
    if (holidays == null) {
      return null
    } else {
      const isHoliday = holidays.includes(date.toISOString().slice(0, 10));
      return isHoliday ? 'holiday' : null;
    }
  };

  const tileDisabledDate = ({ date }) => {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset * 60 * 1000))
    if (closingDates == null) {
      return false
    } else {
      return closingDates.includes(date.toISOString().slice(0, 10));
    }
  };


  return (
    <Paper style={{ minHeight: '100vh' }}>
      <div className="navbar">
        <div className="navContainer">
          <IconButton style={{ position: 'absolute' }} onClick={() => history.push("/Welcome")}><CloseIcon /></IconButton>
          <span className="logo">Booking Calendar </span>
        </div>
      </div>
      <div className={classes.calendarContainer} >
        <Calendar tileClassName={getTileClassName} tileDisabled={tileDisabledDate} calendarType="US" locale='en-US' onChange={date => onDateChange(date)} value={selectedDate} minDate={new Date()} maxDate={futureDate} />
      </div>


      {isfetching ?

        <div style={{ minHeight: '200px', display: 'flex', justifyContent: 'center', margin: 'auto', alignItems: 'center' }}><CircularProgress thickness={10} size={100} /></div>
        :
        (availableTimeSlots.length == 0 ?
          <div style={{ minHeight: '200px', display: 'flex', justifyContent: 'center', margin: 'auto', alignItems: 'center' }}> <Box display="flex" alignItems="center" sx={{ justifyContent: 'space-around' }}><h1>No Available Sessions</h1></Box></div>
          :
          <div style={{ minHeight: '200px' }}>
            <Box display="flex" alignItems="center" sx={{ justifyContent: 'space-around' }}>
              <FormControl variant="outlined" style={{ display: 'flex', alignItems: 'center', marginBottom: '20', width: 'fit-content' }}>
                <span style={{ color: "black", fontFamily: '"Lato Light", sans-serif', fontSize: '17px' }}>Session</span>
                <Select
                  native
                  value={selectedTime}
                  onChange={handleTimeSlotChange}
                  label="Session"
                  inputProps={{
                    name: 'session',
                    id: 'outlined-age-native-simple',
                  }}
                  input={<BootstrapInput />}
                >
                  {timeSlots ? timeSlots.map((item, index) => {
                    return (
                      <option key={item.timeSlotText} value={item.timeSlotTime}> {item.timeSlotText} </option>);
                  }) : null}
                </Select>

              </FormControl>
              <FormControl variant="outlined" style={{ display: 'flex', alignItems: 'center', marginBottom: '20' }}>
                <span style={{ color: "black", fontFamily: '"Lato Light", sans-serif', fontSize: '17px' }}>Guests</span>
                <Select
                  native
                  value={numOfGuest}
                  onChange={handleGuestChange}
                  label="Guest"
                  input={<BootstrapInput />}
                  disabled={noOfGuests == null || noOfGuests.length == 1 || availableSeats == 0
                  }
                >
                  {noOfGuests ? noOfGuests.map((item, index) => ((item == 0 || item <= availableSeats - 1) ?
                    <option key={item} value={item}> {item} </option> : <option key={item} value={item} disabled> {item} </option>)) : null}

                </Select>
              </FormControl>
            </Box>
            <Box display="flex" alignItems="center" sx={{ justifyContent: 'space-around' }}>
              <Box width="100%">
                <BorderLinearProgress progresscolor={progressColor}
                  variant="determinate" value={(timeSlots === null ? 0 : timeSlots.length === 0 ? 0 : ((availableSeats / capacity) * 100))} />
              </Box>
              <Box minWidth={35} sx={{ position: 'absolute' }} >
                <Typography component={'span'} variant={'body2'} style={{ color: "white", left: "30%", top: '50%', transform: "translateY('-50%')", fontSize: '1.5rem' }}>
                  {(availableSeats <= 0 || timeSlots.length === 0) ? 'The session is full' :
                    (availableSeats === 1 ? `${availableSeats} seat left` : `${availableSeats} seats left`)}
                </Typography>
              </Box>
            </Box>

            <Button disabled={timeSlots.length === 0 || availableSeats <= 0 ? true : false}
              style={{ display: 'flex', justifyContent: 'center', marginTop: '30', margin: '25 auto auto auto', height: '45px', fontSize: '20px', fontWeight: 'bold' }}
              variant="contained"
              className={classes.btn}
              onClick={handleClickOpen}>
              Book
            </Button>
          </div>
        )
      }
      <div>
        <DialogBox
          open={open}
          onClose={handleClose}
          title={title}
          context={context}
          boxAction={boxAction}
        />
      </div>

      <Reminders capacity={7} />
    </Paper>

  )
}


