import React from 'react'
import LUCAS_Logo from '../../../images/LUCAS_Logo.png';

export function Footer() {
  return (
    // <div style={{minHeight: '30px'}}>
      <div className='footer'>
        <div style={{ fontFamily: '"Lato Light", sans-serif', justifyContent: 'right'}}>Supported By &nbsp;</div>
        <img src={LUCAS_Logo} alt="LUCAS_Logo.png" style={{ maxWidth: '50', justifyContent: 'right' }} />
      </div>
    // </div>

  )
}
