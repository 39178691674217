import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogBox(props) {
    const { open, onClose, title, context, boxAction } = props

    return (
        <><Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ minWidth: '300' }}
        >
            {<DialogTitle id="alert-dialog-title" style={{ color: "rgb(226, 33, 28)" }}>
                {title}
            </DialogTitle>}
            <DialogContent>
                <DialogContentText id="alert-dialog-description" component={'span'} variant={"body2"} style={{ alignItems: 'center' }}>
                    {context}
                </DialogContentText>
            </DialogContent>
            {boxAction}
        </Dialog></>
    )
}
