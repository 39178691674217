import { call, put } from 'redux-saga/effects'
import { Actions } from '../actions'
import { requestListReservation } from '../requests/requestListReservation'
import { Buffer } from 'buffer'


function* GetListReservation(action) {
    console.log("GetListReservation action : ", action)
    try {
        const response = yield call(requestListReservation.REQUEST_GET_RESERVATION, action.payload.getReservation);
        const { data } = response;

        console.log("GetListReservation data: ", data);
        yield put({ type: Actions.ListReservation.REQUEST_GET_RESERVATION_SUCCEEDED, payload: data.gymRoomReservations })

    } catch (error) {
        console.log(error.message)
    }
}



function* DeleteReservation(action) {
    console.log("DeleteReservation action : ", action)
    try {
        const response = yield call(requestListReservation.REQUEST_DELETE_RESERVATION, action.payload.ReservationID);
        const { data } = response;
        console.log("GetListReservation data: ", data);
        yield put({ type: Actions.ListReservation.REQUEST_DELETE_RESERVATION_SUCCEEDED, payload: action.payload.ReservationID });

    } catch (error) {
        console.log(error.message);
        yield put({ type: Actions.ListReservation.REQUEST_DELETE_RESERVATION_FAILED });
    }
}

function* GetReservationDetail(action) {
    console.log("GetReservationDetail action : ", action)
    console.log("action.payload.ReservationID : ", action.payload.ReservationID)
    try {
        const response = yield call(requestListReservation.REQUEST_GET_RESERVATION_DETAIL, action.payload.ReservationID);
        const { data } = response;
        const returnImageInBase64 = Buffer.from(data, 'binary').toString('base64');

        yield put({ type: Actions.ListReservation.REQUEST_GET_RESERVATION_DETAIL_SUCCEEDED, payload: returnImageInBase64 });

    } catch (error) {
        console.log(error.message);
        yield put({ type: Actions.ListReservation.REQUEST_GET_RESERVATION_DETAIL_FAILED, payload: action.payload.ReservationID });
    }
}



export const ListReservation = { GetListReservation, DeleteReservation, GetReservationDetail }