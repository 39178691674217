import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useGraph } from "@microsoft/teamsfx-react";
import { useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';
import leisureroomimage from '../../../images/leisureroomimage.png';
import LUCAS_Logo from '../../../images/LUCAS_Logo.png';
import '../../App.css';
import { getToken, getUsername } from "../../auth/GetToken";
import { TeamsFxContext } from "../../Context";
import { Footer } from "../Views/Footer";

export function Welcome(props) {

  const history = useHistory();
  const [userName, setUserName] = useState("")

  const { teamsfx } = useContext(TeamsFxContext);
  const { loading, error } = useGraph(
    async (graph, teamsfx, scope) => {
      return teamsfx.getUserInfo();
    },
    { scope: ["User.Read"], teamsfx: teamsfx }
  );


  getToken().then((clientSideToken) => {
    console.log("token:" + clientSideToken);
    setUserName(getUsername(clientSideToken))
    sessionStorage.setItem("accessTokenForGraph", clientSideToken);
    sessionStorage.setItem("accessTokenForGraphExpTime", JSON.parse(Buffer.from(clientSideToken.split('.')[1], 'base64').toString()).exp);
  });

  return (
    <>
      {loading || error ?
        <Paper style={{ minHeight: '100%', background: '#505050 ' }}>
          <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
            <img src={LUCAS_Logo} alt="LUCAS_Logo" style={{ marginTop: '30vh', height: '10vh' }} />
          </div>
          <div style={{ fontSize: 35, color: 'white', display: 'flex', justifyContent: 'center', marginTop: '5vh' }}>LEISURE ROOM</div>
          <SyncLoader style={{ display: 'flex', margin: 'auto', height: '5vh', justifyContent: 'center', marginTop: '5vh' }} />
        </Paper>
        :

        <Paper style={{ minHeight: '100%', fontFamily: '"Lato Light", sans-serif' }}>
          <div className='container'>
            <div className='content'>
              <div className="headerImage">
                <img id='leisureroomimage' className='img' src={leisureroomimage} alt="leisureroomimage.png"></img>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                <div style={{ fontFamily: '"Lato Light", sans-serif', fontSize: 45, color: 'rgb(171, 182, 203)', margin: 'auto', textAlign: 'center', lineHeight: '0.9' }}>LEISURE ROOM BOOKING</div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }}>
                {!loading && error && (
                  <div className="error">Failed init TeamsFx</div>
                )}
                {!loading && <div style={{ fontFamily: '"Lato Light", sans-serif', fontSize: 20, color: 'Black', margin: 'auto' }} >Welcome, {userName}!</div>}
              </div>

              <div style={{ height: 'auto%', display: 'flex', alignItems: 'center' }}>
                <Button className="btn" variant="contained" style={{ width: '50%', margin: 'auto', color: 'rgba(226, 33, 28, 0.698)', background: 'white', fontSize: '16', fontWeight: 'bold', fontFamily: '"Lato Light", sans-serif' }}
                  onClick={() => history.push("/MySchedule")}>My Bookings</Button>
              </div>

              <div className="line-wrapper">
                <div className="line"></div>
                <div className="word-wrapper">
                  <span className="word">OR</span>
                </div>
              </div>

              <div style={{ height: '40px' }} />

              <div style={{ height: 'auto', display: 'flex', alignItems: 'center' }}>
                <Button className="btn" variant="contained" size="large" style={{ width: '50%', margin: 'auto', color: 'white', background: 'rgb(226, 33, 28)', fontSize: '16', fontWeight: 'bold', fontFamily: '"Lato Light", sans-serif' }}
                  onClick={() => history.push("/BookingCalendar")}>Book now</Button>
              </div>

            </div>
            < Footer />
          </div>
        </Paper>
      }
    </>
  );


};

export default (Welcome)

