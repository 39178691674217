const REQUEST_ADD_RESERVATION = "REQUEST_ADD_RESERVATION"
const REQUEST_GET_RESERVATION = "REQUEST_GET_RESERVATION"
const REQUEST_DELETE_RESERVATION = "REQUEST_DELETE_RESERVATION"
const REQUEST_GET_USER = "REQUEST_GET_USER"
const REQUEST_GET_TIMESLOTS = "REQUEST_GET_TIMESLOTS"
const REQUEST_RESELECT_TIMESLOT = "REQUEST_RESELECT_TIMESLOT"
const FINISH_BOOKING = "FINISH_BOOKING"
const REQUEST_GET_AVAILABLE_SEATS = "REQUEST_GET_AVAILABLE_SEATS"
const REQUEST_ADD_RESERVATION_SUCCEEDED = "REQUEST_ADD_RESERVATION_SUCCEEDED"
const REQUEST_GET_RESERVATION_SUCCEEDED = "REQUEST_GET_RESERVATION_SUCCEEDED"
const REQUEST_DELETE_RESERVATION_SUCCEEDED = "REQUEST_DELETE_RESERVATION_SUCCEEDED"
const REQUEST_GET_USER_SUCCEEDED = "REQUEST_GET_USER_SUCCEEDED"
const REQUEST_GET_TIMESLOTS_SUCCEEDED = "REQUEST_GET_TIMESLOTS_SUCCEEDED"
const REQUEST_GET_AVAILABLE_SEATS_SUCCEEDED = "REQUEST_GET_AVAILABLE_SEATS_SUCCEEDED"
const REQUEST_ADD_RESERVATION_FAILED = "REQUEST_ADD_RESERVATION_FAILED"
const REQUEST_GET_RESERVATION_FAILED = "REQUEST_GET_RESERVATION_FAILsED"
const REQUEST_DELETE_RESERVATION_FAILED = "REQUEST_DELETE_RESERVATION_FAILED"
const REQUEST_GET_USER_FAILED = "REQUEST_GET_USER_FAILED"
const REQUEST_GET_TIMESLOTS_FAILED = "REQUEST_GET_TIMESLOTS_FAILED"
const REQUEST_GET_AVAILABLE_SEATS_FAILED = "REQUEST_GET_AVAILABLE_SEATS_FAILED"
const REQUEST_CONFIRM_BOOKING = "REQUEST_CONFIRM_BOOKING"

const REQUEST_GET_NOOFGUESTS = "REQUEST_GET_NOOFGUESTS"
const REQUEST_GET_NOOFGUESTS_SUCCEEDED = "REQUEST_GET_NOOFGUESTS_SUCCEEDED"
const REQUEST_GET_NOOFGUESTS_FAILED = "REQUEST_GET_NOOFGUESTS_FAILED"


const REQUEST_GET_HOLIDAY = "REQUEST_GET_HOLIDAY"
const REQUEST_GET_HOLIDAY_SUCCESS = "REQUEST_GET_HOLIDAY_SUCCESS"

const REQUEST_GET_CLOSINGDATE = "REQUEST_GET_CLOSINGDATE"
const REQUEST_GET_CLOSINGDATE_SUCCESS = "REQUEST_GET_CLOSINGDATE_SUCCESS"

const fn_RequestAddReservation = addReservation => ({
    type: REQUEST_ADD_RESERVATION,
    payload: { addReservation }
})

const fn_RequestDeleteReservation = deleteReservation => ({
    type: REQUEST_DELETE_RESERVATION,
    payload: { deleteReservation }
})

const fn_GetUser = profile => ({
    type: REQUEST_GET_USER,
    payload:profile
})

const fn_RequestGetTimeSlots = date => ({
    type: REQUEST_GET_TIMESLOTS,
    payload: date
})

const fn_RequestGetNoOfGuests = date => ({
    type: REQUEST_GET_NOOFGUESTS,
    payload: date
})

const fn_RequestBookAnotherTimeSlot = () => ({
    type: REQUEST_RESELECT_TIMESLOT,
})

const fn_FinishBooking = () => ({
    type: FINISH_BOOKING,
})

const fn_RequestGetAvailableSeat = timeslot => ({
    type: REQUEST_GET_AVAILABLE_SEATS,
    payload:  timeslot ,
})

const fn_RequestConfirmBooking = () => ({
    type: REQUEST_CONFIRM_BOOKING,
})

const fn_GetHoliday = () => ({
    type: REQUEST_GET_HOLIDAY,
})

const fn_GetClosingDate = () => ({
    type: REQUEST_GET_CLOSINGDATE,
})

export const Actions = {
    fn_RequestAddReservation,
    fn_RequestDeleteReservation,
    fn_GetUser,
    fn_RequestGetTimeSlots,
    fn_RequestBookAnotherTimeSlot,
    fn_FinishBooking,
    fn_RequestGetAvailableSeat,
    fn_RequestGetNoOfGuests,
    fn_RequestConfirmBooking, 
    fn_GetHoliday,
    fn_GetClosingDate,
    REQUEST_ADD_RESERVATION,
    REQUEST_ADD_RESERVATION_FAILED,
    REQUEST_ADD_RESERVATION_SUCCEEDED,
    REQUEST_DELETE_RESERVATION,
    REQUEST_DELETE_RESERVATION_FAILED,
    REQUEST_DELETE_RESERVATION_SUCCEEDED,
    REQUEST_GET_RESERVATION,
    REQUEST_GET_RESERVATION_FAILED,
    REQUEST_GET_RESERVATION_SUCCEEDED,
    REQUEST_GET_USER,
    REQUEST_GET_USER_SUCCEEDED,
    REQUEST_GET_USER_FAILED,
    REQUEST_GET_TIMESLOTS,
    REQUEST_GET_TIMESLOTS_SUCCEEDED,
    REQUEST_GET_TIMESLOTS_FAILED,
    REQUEST_RESELECT_TIMESLOT,
    FINISH_BOOKING,
    REQUEST_GET_AVAILABLE_SEATS,
    REQUEST_GET_AVAILABLE_SEATS_SUCCEEDED,
    REQUEST_GET_AVAILABLE_SEATS_FAILED,
    REQUEST_GET_NOOFGUESTS,
    REQUEST_GET_NOOFGUESTS_SUCCEEDED,
    REQUEST_GET_NOOFGUESTS_FAILED,
    REQUEST_CONFIRM_BOOKING,
    REQUEST_GET_HOLIDAY,
    REQUEST_GET_HOLIDAY_SUCCESS,
    REQUEST_GET_CLOSINGDATE,
    REQUEST_GET_CLOSINGDATE_SUCCESS,
}
