import IconButton from '@material-ui/core/IconButton';
import '../../App.css';
import CloseIcon from '@material-ui/icons/Close';
import ScheduledList  from "./ScheduledList";
import { useState } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MyPreviousBooking from "./MyPreviousBooking";
import { useHistory } from 'react-router-dom'; 

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}> 
          <Typography component={'span'}  variant={"body2"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    //minHeight: '700',
    flex: "1 1 auto",
    flexDirection: "column",
    display: "flex"
  },
  btnIcon: {
    "&:hover": {
      backgroundColor:'white'
    }
  },
  navbar:{
    display: 'flex',
    flex: '0 0 50px'
  }
}));



export default function MySchedule(props) {

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <div className={"navbar " + classes.navbar}>
        <div className="navContainer">
          <IconButton style={{ position: 'absolute' }} onClick={() => history.push("/Welcome")} ><CloseIcon /></IconButton>
          <span className="logo" >My Bookings</span>
        </div>
      </div>

      <div className={classes.root}>
        <AppBar position="static" color="default" style={{ flex: '0 0 auto' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Upcoming" {...a11yProps(0)} />
            <Tab label="Previous" {...a11yProps(1)} />

          </Tabs>
        </AppBar>
        <SwipeableViews
          index={value}
          onChangeIndex={handleChangeIndex}
          style={{flex: '1 1 auto'}}

        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <ScheduledList />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction} style={{ flex: '1 1 auto' }}>
            <MyPreviousBooking />
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>

  );
};

