import { Actions } from '../actions';

const initialState = {
    gymRoomReservations: [],
    gymRoomReservationsID:'',
    message:'',
    isDeleteError: false,
    isDeleteSucceeded: false,
    QRCode: [],
    isLoadingReservations:false,
}

export default function ListReservation(state = initialState, action) {
    switch (action.type) {
        case Actions.ListReservation.REQUEST_GET_RESERVATION:
            return Object.assign({}, state, {
                isLoadingReservations:true,
            })
        case Actions.ListReservation.REQUEST_GET_RESERVATION_SUCCEEDED:
            return Object.assign({}, state, {
                gymRoomReservations: action.payload,
                isLoadingReservations:false,
                
            })
        case Actions.ListReservation.REQUEST_DELETE_RESERVATION_SUCCEEDED:
                return Object.assign({}, state, {
                    isDeleteSucceeded:true,
                    gymRoomReservations: state.gymRoomReservations.filter(item=>item.reservationId!==action.payload),
                })
            case Actions.ListReservation.REQUEST_DELETE_RESERVATION_FAILED:
                return Object.assign({}, state, {
                    isDeleteError:true,
                })
                 
         case Actions.ListReservation.FINISH_DELETE_RESERVATION:
            return Object.assign({}, state, {
                isDeleteSucceeded: false,
                isDeleteError: false,
            })
        case Actions.ListReservation.REQUEST_GET_RESERVATION_DETAIL_SUCCEEDED:
            return Object.assign({}, state, {
                QRCode:action.payload,
            })
        case Actions.ListReservation.REQUEST_GET_RESERVATION_DETAIL_FAILED:
            return Object.assign({}, state, {
                QRCode:action.payload,
            })
         
    
        default:
            return state
    }
    
}