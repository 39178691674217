
import { all, takeLatest } from 'redux-saga/effects'
import { Actions } from '../actions'
import { ListReservation } from './ListReservation'
import { Reservation } from "./Reservation"



export default function* rootsaga() {
  yield all([
    takeLatest(Actions.Reservation.REQUEST_GET_NOOFGUESTS, Reservation.GetNoOfGuests),
    takeLatest(Actions.Reservation.REQUEST_GET_TIMESLOTS, Reservation.GetTimeSlots),
    takeLatest(Actions.Reservation.REQUEST_ADD_RESERVATION, Reservation.AddReservation),
    takeLatest(Actions.Reservation.REQUEST_GET_AVAILABLE_SEATS, Reservation.GetAvailableSeats),
    takeLatest(Actions.ListReservation.REQUEST_GET_RESERVATION, ListReservation.GetListReservation),
    takeLatest(Actions.ListReservation.REQUEST_DELETE_RESERVATION, ListReservation.DeleteReservation),
    takeLatest(Actions.ListReservation.REQUEST_GET_RESERVATION_DETAIL, ListReservation.GetReservationDetail ),
    takeLatest(Actions.Reservation.REQUEST_GET_HOLIDAY, Reservation.GetPublicHoliday ),
    takeLatest(Actions.Reservation.REQUEST_GET_CLOSINGDATE, Reservation.GetClosingDate ),
  ])

}


