import * as microsoftTeams from '@microsoft/teams-js';

function getToken() {

    console.log(">>>>>>>>Gettoken")
    microsoftTeams.initialize()
    return getClientSideToken()


}
function getClientSideToken() {
    console.log("getClientSideToken");
    return new Promise((resolve, reject) => {
        let authTokenRequest = {
            successCallback: function (result) {
                resolve(result);
            },
            failureCallback: function (error) { display(error); reject(error); }
        };
        microsoftTeams.authentication.getAuthToken(authTokenRequest);
    })
}

function getServerSideToken(clientSideToken) {
    console.log("getServerSideToken(), clientSideToken=" + clientSideToken);

    return new Promise((resolve, reject) => {
        microsoftTeams.getContext((context) => {
            fetch('/getProfileOnBehalfOf', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'tid': context.tid,
                    'token': clientSideToken
                }),
                mode: 'cors',
                cache: 'default'
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        reject(response.error);
                    }
                })
                .then((responseJson) => {
                    if (responseJson.error) {
                        reject(responseJson.error);
                    } else {
                        const profile = responseJson;
                        resolve(profile);
                    }
                });
        });
    });
}

function display(text) {
    console.log("log: " + text);
    return text;
}

function parseJwt(token) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}

function getUsername(clientSideToken) {
    let user = parseJwt(clientSideToken);
    return user.given_name + ' ' + user.family_name;
}

export { getToken, getServerSideToken, parseJwt, getUsername }