import { call, put } from 'redux-saga/effects'
import { Actions } from '../actions';
import { requestReservation } from '../requests/requestReservation'

function* GetTimeSlots(action) {
    console.log("GetTimeSlots action : ", action)
    try {
        const response = yield call(requestReservation.GetTimeSlots, action.payload);
        const data = response.data.data
         yield put({ type: Actions.Reservation.REQUEST_GET_TIMESLOTS_SUCCEEDED, payload: data})

    } catch (error){
        console.log(error.message);
        yield put({ type: Actions.Reservation.REQUEST_GET_TIMESLOTS_FAILED, payload: error });
        //if fails to get timeSlots, no handling
    }
    
}

function* GetNoOfGuests(action) {
    console.log("GetNoOfGuests action : ", action);
    try {
        const response = yield call(requestReservation.GetNoOfGuests, action.payload);
        const data = response.data;
        yield put({ type: Actions.Reservation.REQUEST_GET_NOOFGUESTS_SUCCEEDED, payload: data });

    } catch (error) {
        console.log(error.message);
        yield put({ type: Actions.Reservation.REQUEST_GET_NOOFGUESTS_FAILED, payload: error });
    }

}

function* GetAvailableSeats(action) {
    console.log("GetAvailableSeats action.payload", { ...action })
    console.log("GetAvailableSeats action.payload.timeslot", action.payload.startTimeForAPI)

    try {
        const response = yield call(requestReservation.GetAvailableSeats, action.payload);
        const data = response.data
        yield put({ type: Actions.Reservation.REQUEST_GET_AVAILABLE_SEATS_SUCCEEDED, payload: data})

    } catch (error){
        console.log("error.message:", error.message);
        yield put({ type: Actions.Reservation.REQUEST_GET_AVAILABLE_SEATS_FAILED, payload: error})
        //if fails to get timeSlots, no handling, as it would shows "this timeslots is full"
    }
    
}

function* AddReservation(action) {
   
    console.log("AddReservation action.payload.addReservation :", action.payload.addReservation);
    console.log("AddReservation typeof action.payload.addReservation :", typeof action.payload.addReservation);
        
    try {
        console.log("AddReservation action :", action);
        const response = yield call(requestReservation.AddReservation, action.payload.addReservation);
        
        const { data } = response;
        console.log("data:", data);
        console.log("response:", response);
        yield put({ type: Actions.Reservation.REQUEST_ADD_RESERVATION_SUCCEEDED})

    } catch (error) {
        const errorData = { ...error }.response.data

        console.log("errorData", errorData.message);
        yield put({ type: Actions.Reservation.REQUEST_ADD_RESERVATION_FAILED, payload: errorData.message });
    }
    
}

function* GetPublicHoliday(action) {
    console.log("GetPublicHoliday action : ", action)
    try {
        const response = yield call(requestReservation.GetPublicHoliday);
        const { data } = response.data;

        console.log("GetPublicHoliday data: ", data);
        yield put({ type: Actions.Reservation.REQUEST_GET_HOLIDAY_SUCCESS, payload: data })

    } catch (error) {
        console.log(error.message)
    }
}

function* GetClosingDate(action) {
    console.log("GetClosingDate action : ", action)
    try {
        const response = yield call(requestReservation.GetClosingDate);
        const { data } = response.data;

        console.log("GetClosingDate data: ", data);
        yield put({ type: Actions.Reservation.REQUEST_GET_CLOSINGDATE_SUCCESS, payload: data })

    } catch (error) {
        console.log(error.message)
    }
}

export const Reservation = { GetTimeSlots, AddReservation, GetAvailableSeats, GetNoOfGuests, GetPublicHoliday, GetClosingDate}