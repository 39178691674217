import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_REQUEST_BASE,
    strictSSL: false,
    rejectUnauthorized: false,
});

function setAuthHeader(){
    instance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('accessTokenForGraph')}`;
}

function REQUEST_GET_RESERVATION(action) {
    setAuthHeader();
    return instance.get(`/listMyGymReservations/${action}`);
}

function GetNoOfGuests(action) {
    setAuthHeader();
    return instance.get(`/listNumberOfGuests/${action}`);
}

function GetTimeSlots(action) {
    setAuthHeader();
    return instance.get(`/getGymOpeningHours/${action}`);
}

function GetAvailableSeats(action) {
    setAuthHeader();
    return instance.post(`/getAvailability/`, {
        start: action.startTimeForAPI,
        end: action.endTimeForAPI,
    });
}

function AddReservation(action) {
    setAuthHeader();
    return instance.post(`/addGymReservationWithGuests`, {
        start: action.startTimeForAPI,
        end: action.endTimeForAPI,
        numOfGuest: action.numOfGuest
    });
}

function GetPublicHoliday() {
    setAuthHeader();
    return instance.get(`/getPublicHoliday`);
}

function GetClosingDate() {
    setAuthHeader();
    return instance.get(`/getClosingDate`);
}

export const requestReservation = { REQUEST_GET_RESERVATION, GetTimeSlots, AddReservation, GetAvailableSeats, GetNoOfGuests, GetPublicHoliday, GetClosingDate}
