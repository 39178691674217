
const REQUEST_GET_RESERVATION = "REQUEST_GET_RESERVATION"
const REQUEST_DELETE_RESERVATION = "REQUEST_DELETE_RESERVATION"
const REQUEST_GET_RESERVATION_DETAIL = "REQUEST_GET_RESERVATION_DETAIL"

const REQUEST_GET_RESERVATION_SUCCEEDED = "REQUEST_GET_RESERVATION_SUCCEEDED"
const REQUEST_DELETE_RESERVATION_SUCCEEDED = "REQUEST_DELETE_RESERVATION_SUCCEEDED"
const REQUEST_GET_RESERVATION_DETAIL_SUCCEEDED = "REQUEST_GET_RESERVATION_DETAIL_SUCCEEDED"

const REQUEST_GET_RESERVATION_FAILED = "REQUEST_GET_RESERVATION_FAILsED"
const REQUEST_DELETE_RESERVATION_FAILED = "REQUEST_DELETE_RESERVATION_FAILED"
const REQUEST_GET_RESERVATION_DETAIL_FAILED= "REQUEST_GET_RESERVATION_DETAIL_FAILED"

const FINISH_DELETE_RESERVATION = "FINISH_DELETE_RESERVATION"

const fn_RequestGetReservation = getReservation => ({
    type: REQUEST_GET_RESERVATION,
    payload: { getReservation }
})

const fn_RequestDeleteReservation = ReservationID => ({
    type: REQUEST_DELETE_RESERVATION,
    payload: { ReservationID }
})

const fn_FinishDeleteReservation = () => ({
    type: FINISH_DELETE_RESERVATION,
})


const fn_RequestGetReservationDetail = ReservationID => ({
    type: REQUEST_GET_RESERVATION_DETAIL,
    payload: { ReservationID }
})



export const Actions = {

    fn_RequestGetReservation,
    fn_RequestDeleteReservation,
    fn_FinishDeleteReservation,
    fn_RequestGetReservationDetail,


    REQUEST_DELETE_RESERVATION,
    REQUEST_DELETE_RESERVATION_FAILED,
    REQUEST_DELETE_RESERVATION_SUCCEEDED,
    
    REQUEST_GET_RESERVATION,
    REQUEST_GET_RESERVATION_FAILED,
    REQUEST_GET_RESERVATION_SUCCEEDED,

    FINISH_DELETE_RESERVATION,

    REQUEST_GET_RESERVATION_DETAIL,
    REQUEST_GET_RESERVATION_DETAIL_SUCCEEDED,
    REQUEST_GET_RESERVATION_DETAIL_FAILED

}
