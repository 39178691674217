import React from 'react';

function Reminders(props) {
  return (
    <div style={{ paddingBottom: 5, borderRadius: 10, paddingTop: 20 }}>
      <p style={{ color: 'red', fontWeight: 'bold', paddingLeft: 20 }}>Reminders</p>
      <ol>
        <li>The booking session is 1-hour per session.</li>
        <li>Each person can reserve one session only each day.</li>
        <li>The booking can be made within the next 30 days.</li>
        <li>Maximum 2 sessions on different dates can be reserved, excluding an instant booking for any vacant sessions in the coming 2 hours on first come first basis.</li>
        <li>{props.capacity} openings are available for booking in each session.</li>
        <li>All users should leave the room when their booked session is over.  All equipment there should be put back to its original place after use.</li>
        <li>For safety reasons, persons entering the leisure room and using the facilities there are limited to those aged of 15 or above.</li>
      </ol>
    </div>
  )
}

export default Reminders;
