import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_REQUEST_BASE,
    strictSSL: false,
    rejectUnauthorized: false,
    // headers: {'Authorization':'Bearer '+ token}
});

function setAuthHeader() {
    instance.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('accessTokenForGraph')}`;
}

function REQUEST_GET_RESERVATION(action) {
    setAuthHeader();
    return instance.post(`/listMyGymReservations/`, {
        start: action.start,
        end: action.end
    });
}

function REQUEST_DELETE_RESERVATION(action) {
    setAuthHeader();
    return instance.put(`deleteGymReservation/${action}`);
}

function REQUEST_GET_RESERVATION_DETAIL(action) {
    setAuthHeader();
    return instance.get(`/getQRCode/${action}`, {
        headers: { 'Content-Type': 'image/png' },
        responseType: "arraybuffer"
    });
}

export const requestListReservation = { REQUEST_GET_RESERVATION, REQUEST_DELETE_RESERVATION, REQUEST_GET_RESERVATION_DETAIL }