import React from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import  Welcome  from "./BookComponents/Home/Welcome";
import "./App.css";
import TabConfig from "./TabConfig";
import MySchedule from "./BookComponents/MyReservation/MySchedule"
import BookingCalendar from "./BookComponents/BookNow/BookingCalendar";

import { useTeamsFx } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";


/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App(  ) {
  const { loading, theme, themeString, teamsfx } = useTeamsFx();

  return (
    <TeamsFxContext.Provider value={{theme, themeString, teamsfx}}>

    <Provider theme={theme || teamsTheme} styles={{ backgroundColor: "#eeeeee" }}>
      {/* the route helps to route the page to different component */}
      <Router>
          <Route exact path="/">
            <Redirect to="/Welcome" />
          </Route>
          {loading ? (
            <Loader style={{ margin: 100 }} />
          ) : (
            <>
              <Route exact path="/privacy" component={Privacy } />
              <Route exact path="/termsofuse" component={TermsOfUse} />
              <Route exact path="/Welcome" component={Welcome} />
              <Route exact path="/config" component={TabConfig} />
              <Route exact path="/MySchedule" component={MySchedule} />
              <Route exact path="/BookingCalendar" component={BookingCalendar} />
            </>
          )}
        </Router>
    </Provider>
    </TeamsFxContext.Provider>
  );
}
