import MaterialTable from 'material-table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../actions';
import { GetMaterialTableIcon } from "./GetMaterialTableIcon";


export default function MyPreviousBooking(props) {
  const [today] = useState(new Date());
  const [starttime] = useState(moment().subtract(1, 'months'));

  let defaultEndDate = new Date();
  defaultEndDate.setDate(new Date(new Date().setHours(today.getHours() - 1)));
  const [selectedEndDate] = useState(moment().subtract(1, 'hours'));

  const gymRoomReservations = useSelector((state) => state.ListReservation.gymRoomReservations)
  const isLoadingUpcomingReservations = useSelector((state) => state.ListReservation.isLoadingReservations)
  const dispatch = useDispatch()


  const [columns] = useState([
    { title: '', field: 'date' },
    { title: '', field: 'startTimeDisplay', type: 'time' },
  ]);

  const [data, setData] = useState([

  ]);


  useEffect(() => {
    const getReservationObject = {start: starttime.format("YYYY-MM-DD HH:mm:ss"), end :selectedEndDate.format("YYYY-MM-DD HH:mm:ss")}

    console.log("getReservationObject: ", getReservationObject);
    dispatch(Actions.ListReservation.fn_RequestGetReservation(getReservationObject));
  }, [today, starttime, selectedEndDate, dispatch])

  useEffect(() => {
    console.log("gymRoomReservations", gymRoomReservations)
    let renderReservation = [];
    if (gymRoomReservations.length !== 0) {
      gymRoomReservations.forEach(item => {
        const reservationID = item.reservationId;
        const date = moment(item.startTime).format('MMM D') + ' (' + moment(item.startTime).format('ddd') + ')';
        const startTimeDisplay = moment(item.startTime).format('LT') + ' - ' + moment(item.endTime).format('LT');
        const reservationItem = { reservationID, date, startTimeDisplay };
        renderReservation.push(reservationItem);
      })
    }
    console.log("renderReservation: ", renderReservation)
    setData(renderReservation);
  }, [gymRoomReservations])


  return (
    <>
      <MaterialTable
        icons={GetMaterialTableIcon()}
        title="Previous Bookings"
        columns={columns}
        data={data}
        isLoading={isLoadingUpcomingReservations}
        options={{
          showTitle: false,
          toolbar: false,
          search: false,
          paging:true,
          pageSize:10,
          pageSizeOptions:[10,20,30],
          header: false
        }}
      />
    </>
  );
}
