
import ReactDOM from "react-dom";
import App from "./components/App";
import "./components/App.css";
import "./index.css";
import { Provider } from 'react-redux';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducer from './components/reducer';
import rootsaga from "./components/sagas";

const middlewares = []

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger({
      duration: true,
      collapsed: (getState, action, logEntry) => !logEntry.error
  }));
}

const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(...middlewares)
))

sagaMiddleware.run(rootsaga)


ReactDOM.render(
  <Provider store={store} >
    <div style={{maxWidth:'500', minHeight: '600', margin:'auto'}}><App/></div>
  </Provider>, document.getElementById("root"));