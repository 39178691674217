import { default as Grid, default as Item } from '@material-ui/core/Grid';
import { useGraph } from "@microsoft/teamsfx-react";
import 'date-fns';
import moment, { now } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { TeamsFxContext } from "../../Context";

import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import NotesIcon from '@material-ui/icons/Notes';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MaterialTable from 'material-table';
import { Actions } from '../../actions';
import { GetMaterialTableIcon } from "./GetMaterialTableIcon";

import { IconButton, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SendIcon from '@material-ui/icons/Send';
import { useDispatch, useSelector } from 'react-redux';
import './ListTable.css';
import ReservationDetail from './ReservationDetail';

export default function ScheduledList(props) {

  const [today] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  let defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() + 7);
  const [selectedEndDate, setSelectedEndDate] = useState(defaultEndDate);
  const [invaildEndDate, setInvaildEndDate] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [showReservationDetail, setShowReservationDetail] = useState(false)
  const [open, setOpen] = React.useState(false);
  const gymRoomReservations = useSelector((state) => state.ListReservation.gymRoomReservations)
  const isDeleteSucceeded = useSelector((state) => state.ListReservation.isDeleteSucceeded)
  const isLoadingUpcomingReservations = useSelector((state) => state.ListReservation.isLoadingReservations)
  const isDeleteError = useSelector((state) => state.ListReservation.isDeleteError)


  
  const dispatch = useDispatch()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedRowData({});
    setOpen(false);
  };

  const handleReservationDetailClose = () => {
    setShowReservationDetail(false);
    setSelectedRowData({});
  }

  const columns = [
    {
      title: '',
      width: '5%',
      render: (rowData) =>{
        const button = (
          <Tooltip title="Delete">
            <IconButton
              color="inherit"
              onClick={(event) => {
                setSelectedRowData(rowData);
                handleClickOpen();
              }}
            >
              <DeleteIcon/>
            </IconButton>
          </Tooltip>

        );
        return button;
      }
    },
    { title: '', field: 'date', },
    { title: '', field: 'startTimeDisplay', type: 'time', },
    {
      title: "",
      width: '5%',
      render: (rowData) => {
        const button = (
          <Tooltip title="Details">
            <IconButton
              color="inherit"
              onClick={(event) => {
                setShowReservationDetail(true);
                setSelectedRowData(rowData);
              }}
            >
              <NotesIcon />
            </IconButton>
          </Tooltip>
        );
        return button;
      }
    }
  ];

  const [data, setData] = useState([]);

  //handle expired tokens
  const teamsfx = useContext(TeamsFxContext);
  useGraph(
    async (graph, teamsfx, scope) => {
      let expTimeStored = sessionStorage.getItem('accessTokenForGraphExpTime');

      if (expTimeStored !== null && expTimeStored < now()) {
        console.log("accessTokenForGraph expired");
        const accessTokenForGraphC = await teamsfx.getCredential().getToken('https://graph.microsoft.com/User.Read');
        sessionStorage.setItem("accessTokenForGraph", accessTokenForGraphC.token);
        sessionStorage.setItem("accessTokenForGraphExpTime", accessTokenForGraphC.expiresOnTimestamp);
      }
    }, [teamsfx]);

  // for calling API when startdate and endate changes
  useEffect(() => {
    let renderReservation = [];
    if (gymRoomReservations.length !== 0) {
      gymRoomReservations.forEach(item => {
        const reservationID = item.reservationId;
        const date = moment(item.startTime).format('MMM D') + ' (' + moment(item.startTime).format('ddd') + ')';
        const startTimeDisplay = moment(item.startTime).format('LT') + ' - ' + moment(item.endTime).format('LT');
        const guests = item.noOfGuests;
        const reservationItem = { reservationID, date, startTimeDisplay, guests };
        renderReservation.push(reservationItem);
      })
    }
    console.log("renderReservation: ", renderReservation)
    setData(renderReservation);
  }, [gymRoomReservations])

  useEffect(() => {
    let startDateObj = moment(selectedStartDate, "YYYY-MM-DD HH:mm:ss");
    let endDateObj = moment(selectedEndDate, "YYYY-MM-DD HH:mm:ss").set({hour: 23, minute: 59, second: 59});
    if (moment(today).format('L') === startDateObj.format('L')) {
      // call API on the the current moment on the same date
      console.log("call API with the current moment on the same date");
    } else {
      // call API on the the 00:00:00 on the that date
      console.log("call API with 00:00:00 on the date");
      startDateObj.set({hour: 0, minute: 0, second: 0});
    }
    const getReservationObject = {start: startDateObj.format("YYYY-MM-DD HH:mm:ss"), end : endDateObj.format("YYYY-MM-DD HH:mm:ss")}
    dispatch(Actions.ListReservation.fn_RequestGetReservation(getReservationObject));
  }, [selectedStartDate, selectedEndDate, dispatch])


  function handleDateChange(date, id) {

    if (id === "selectedStartDate") {
      if (date.getTime() > selectedEndDate.getTime()) {
        setSelectedStartDate(date);
        setSelectedEndDate(date);
        setInvaildEndDate(false)
      } else {
        setSelectedStartDate(date);
      }
    }

    if (id === "selectedEndDate") {
      setSelectedEndDate(date);
      setInvaildEndDate(false);
    }
  }

  return (
    <>
      <MaterialTable
        icons={GetMaterialTableIcon()}
        title="Upcoming Bookings"
        columns={columns}
        options={{
          showTitle: false,
          search: false,
          tableLayout: "auto",
          paging:false,
          pageSize:7,
          pageSizeOptions:[7,10,20],
        }}
        style={{minHeight: '80vh'}}
        data={data}
        isLoading={isLoadingUpcomingReservations}
        components={{
          // Row: props => (
          // ),
          Toolbar: () => (
            <div>
              {/* <MTableToolbar {...props} /> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around" spacing={1}>
                  <Grid item xs={5}>
                    <Item>
                      <KeyboardDatePicker
                        margin="normal"
                        id="selectedStartDate"
                        label="Start Date"
                        format="M/d/yyyy"
                        minDate={moment(today).format("YYYY-MM-DD")}
                        value={selectedStartDate}
                        onChange={date => handleDateChange(date, "selectedStartDate")}
                      /> 
                    </Item>
                  </Grid>
                  <Grid item xs={5}>
                    <Item>
                      <KeyboardDatePicker
                        margin="normal"
                        id="selectedEndDate"
                        label="End Date"
                        format="M/d/yyyy"
                        value={selectedEndDate}
                        minDate={moment(selectedStartDate).format("YYYY-MM-DD")}
                        onChange={date => handleDateChange(date, "selectedEndDate")}
                        error={invaildEndDate}
                        helperText={invaildEndDate ? "Please selected a date on or after start date" : ""}
                      />
                    </Item>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button variant="outlined" style={{ textTransform: 'capitalize', margin: 'auto', marginTop: '10', justifyContent: "space-around", fontWeight: 'bold', color: 'rgba(226, 33, 28, 0.698)', background: 'white', fontFamily: '"Lato Light", sans-serif' }}
                  onClick={() => {let endDate = new Date(); endDate.setDate(today.getDate() + 30); setSelectedStartDate(today); setSelectedEndDate(endDate)}} >
                  Next 30 days
                </Button>
                <Button variant="outlined" style={{ textTransform: 'capitalize', margin: 'auto', marginTop: '10', justifyContent: "space-around", fontWeight: 'bold', color: 'rgba(226, 33, 28, 0.698)', background: 'white', fontFamily: '"Lato Light", sans-serif' }}
                  onClick={() => {setSelectedStartDate(today); setSelectedEndDate(defaultEndDate)}}  >
                  Next 7 days
                </Button>
                <Button variant="outlined" style={{ textTransform: 'capitalize', margin: 'auto', marginTop: '10', justifyContent: "space-around", fontWeight: 'bold', color: 'rgba(226, 33, 28, 0.698)', background: 'white', fontFamily: '"Lato Light", sans-serif' }}
                  onClick={() => { setSelectedEndDate(today); setSelectedStartDate(today) }} >
                  Today
                </Button>
              </div>
            </div>

          )
        }}
      />

      {showReservationDetail ? <ReservationDetail reservationData={selectedRowData} handleReservationDetailClose={handleReservationDetailClose} /> : null}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isDeleteError ?
            <span style={{ color: "#da1e15", fontWeight: 'bold' }}>Failed to delete!</span> :
            ((isDeleteSucceeded) ? <span style={{ color: '#da1e15', fontWeight: 'bold' }}>Successfully Deleted!</span> :
              <span style={{ color: "#da1e15", fontWeight: 'bold' }}>Confirm Booking Deletion </span>)}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isDeleteError ? <span style={{ color: "black" }}>Error deleting booking, please try again</span> :
              (isDeleteSucceeded ? <span style={{ color: "black" }}>Booking deleted successfully</span> :
                <span style={{ color: "black" }}>Cancel your booking on <span style={{ fontWeight: 'bold' }}>{selectedRowData.date}</span> <span style={{ fontWeight: 'bold' }}>{selectedRowData.startTimeDisplay}</span> ?</span>)
            }
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {isDeleteError ? <Button variant="contained" onClick={() => { handleClose(); dispatch(Actions.ListReservation.fn_FinishDeleteReservation()); }} autoFocus endIcon={<ClearIcon />}>
            Try Again
          </Button> : (isDeleteSucceeded ?
            <Button variant="contained" onClick={() => { handleClose(); dispatch(Actions.ListReservation.fn_FinishDeleteReservation()); }} style={{ color: 'white', background: '#da1e15' }} endIcon={<DoneAllIcon />}>
              Done
            </Button> :
            <>
              <Button variant="contained" onClick={() => { dispatch(Actions.ListReservation.fn_RequestDeleteReservation(selectedRowData.reservationID)) }} style={{ color: 'white', background: '#da1e15' }} endIcon={<SendIcon />}>
                Confirm
              </Button>
              <Button variant="contained" onClick={handleClose} endIcon={<ClearIcon />}>
                Back
              </Button>
            </>)}
        </DialogActions>
      </Dialog>
    </>
  );
}

